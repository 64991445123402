import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Amplify } from '@aws-amplify/core';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import objectSupport from 'dayjs/plugin/objectSupport';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const redirectUrl = window.location.hostname === 'localhost' ? 'http://localhost:4200' : environment.apiUrl;

Amplify.configure({
  ['Auth']: {
    region: environment.region,
    userPoolId: environment.oktaUserPoolId,
    userPoolWebClientId: environment.oktaUserPoolWebClientId,
    oauth: {
      domain: `${environment.oktaUserPoolDomain}.auth.${environment.region}.amazoncognito.com`,
      scope: ['aws.cognito.signin.user.admin', 'openid', 'email'],
      redirectSignIn: redirectUrl,
      redirectSignOut: redirectUrl,
      responseType: 'code',
    },
  },
});

dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(localizedFormat);
dayjs.extend(objectSupport);
dayjs.extend(timezone);
dayjs.extend(utc);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
